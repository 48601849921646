import React from 'react'
import { css } from '@emotion/react'

import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { HeaderNav } from '@/components/section/header/header-nav'
import { headerNavList } from '@/constants/header'
import { FooterNav } from '@/components/section/footer/footer-nav'
import { FooterBottom } from '@/components/section/footer/footer-bottom'
import { footerBottomList } from '@/constants/footer'
import { navList } from '@/constants/footer'
import { Head } from '@/components/head'
import logo_relic_holdings from '@/images/logo_relic_holdings.svg'

export default function NotFound(): JSX.Element {
  return (
    <>
      <Head title="404 | 株式会社Relicホールディングス" url="404" />
      <HeaderNav
        css={cssHeaderNav}
        url="/"
        imgSrc={logo_relic_holdings}
        alt="Relic HOLDINGS"
        navList={headerNavList}
        gradientColor={[color.blue1, color.blue2]}
        btnFontStyleCss={cssBtnFontStyle}
        arrowFillColor={color.white1}
        headerLogoCss={cssHeaderLogoSize}
      />
      <h1 css={cssH1}>
        <span>404</span>
        <span>Not Found</span>
      </h1>
      <div
        css={css`
          border-top: 1px solid ${color.gray1};
        `}
      >
        <FooterNav navList={navList} />
        <FooterBottom
          copyrightLayoutCss={cssFooterBottom}
          footerBottomList={footerBottomList}
          copy="© Copyright 2021 Relic Holdings Inc. All rights reserved."
        />
      </div>
    </>
  )
}

const cssHeaderNav = css`
  padding: 9px 16px;
  box-shadow: 0 3px 6px ${color.black3};
  ${mq.pc} {
    box-shadow: 0 2px 12px ${color.black3};
  }
`

const cssHeaderLogoSize = css`
  width: 40px;
  ${mq.pc} {
    width: 65px;
  }
`

const cssBtnFontStyle = css`
  color: ${color.white1};
`

const cssH1 = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: calc(50vh - 224px) 0 calc(50vh - 300px);
  ${mq.pc} {
    padding: calc(50vh - 133px) 0;
  }
  span {
    font-size: 48px;
    &:last-child {
      font-size: 19px;
    }
  }
`

const cssFooterBottom = css`
  padding: 24px 16px 12px;
`
